
import SalesService from "@/services/SalesService"
import Mark from "mark.js"
import { reactive, defineComponent, watch } from "vue"
import { useRoute } from "vue-router"

export default defineComponent({
  props: {
    selectedProducts: {
      type: Object,
    },
    selectedCategories: {
      type: Object,
    },
    quotation: {
      type: Object,
    },
    searchValue: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    currency: {
      type: String,
    },
    region: {
      type: String,
    },
    activeCategory: {
      type: Object,
      default: null,
    },
  },
  emits: ["close-product-picker-dialog", "refresh-products", "change-loading-state"],
  setup(props, { emit }: any) {
    const route = useRoute()
    const salesService = new SalesService()
    const categories = reactive([])

    watch(
      () => props.searchValue,
      (newValue) => {
        if (newValue) {
          for (let cat of categories) {
            if (cat.alias.toLowerCase().includes(newValue.toString().toLowerCase())) {
              cat.collapsed = false
            } else {
              cat.collapsed = true
            }
          }
          categories.sort((a: any, b: any) => {
            if (!a.collapsed && b.collapsed) return -1
            if (!b.collapsed && a.collapsed) return 1
            else return 0
          })
        } else {
          for (let cat of categories) {
            cat.collapsed = true
          }
          categories.sort((a: any, b: any) => {
            if (a.priority < b.priority) return -1
            if (a.priority > b.priority) return 1
            else return 0
          })
        }
      }
    )

    salesService.getCategories().then((_categories: any) => {
      categories.length = 0
      ;[].push.apply(categories, _categories)
      categories.forEach((cat: any) => {
        cat.collapsed = true
        salesService.getProductsList(cat.id).then((prods: any) => {
          prods.forEach((prod: any) => {
            const found = findProductInQuotation(prod)?.prod
            prod.selected = !!found
          })
          cat.products = prods
        })
      })
    })

    function findProductInQuotation(prod: any): any {
      if (props.quotation?.categories?.length) {
        let found
        props.quotation?.categories.forEach((cat: any) => {
          cat.products.forEach((_prod: any) => {
            if (_prod.product.id === prod.id) {
              found = { prod: _prod, cat }
            }
          })
        })
        return found
      }
    }

    function selectProduct({ prod, cat }: any) {
      //no details for quotation language
      if (
        !prod.details.filter((detail: any) => detail.language === props.quotation.language).length
      ) {
        return
      }
      //no price for quotation currency or region
      if (!prod.prices.filter((price: any) => price.currency === props.currency).length) {
        return
      }
      if (props.loading === true) {
        return
      }
      if (!prod?.prices?.length || !prod?.details?.length) {
        return
      }

      emit("change-loading-state", true)
      prod.selected = !prod.selected

      if (prod.selected) {
        //const catIndex = props?.selectedCategories?.indexOf(cat.id)
        const foundCategory = props.quotation.categories.find(
          (_cat: any) => _cat.productCategory.id === cat.id
        )

        if (!foundCategory) {
          //cat doesn't exist so prod also doesn't
          salesService
            .createQuotationCategory(route.params.quotation_id as string, cat.id, {
              priority: cat.priority,
              mandatory: cat.mandatory,
              multiple: cat.multiple,
            })
            .then((catId: string) => {
              salesService
                .createQuotationProduct(catId, prod.id, {
                  mandatory: prod.mandatory,
                  multiple: prod.multiple,
                  recommended: prod.recommended,
                  selected: false,
                })
                .then(() => {
                  emit("refresh-products")
                })
            })
        } else {
          //cat exists to add it to the category
          salesService
            .createQuotationProduct(foundCategory.id, prod.id, {
              mandatory: prod.mandatory,
              multiple: prod.multiple,
              recommended: prod.recommended,
              selected: false,
            })
            .then(() => {
              emit("refresh-products")
            })
        }
      } else {
        //remove prod
        //find prod id in quotation
        let found: any = findProductInQuotation(prod)
        let foundProd = found?.prod
        let foundCat = found?.cat

        const deleteCat = foundCat?.products.length === 1

        salesService.deleteQuotationProduct(foundProd.id).then(() => {
          //delete unused categories
          if (deleteCat) {
            salesService.deleteQuotationCategory(foundCat.id).then(() => {
              emit("refresh-products")
            })
          } else {
            emit("refresh-products")
          }
        })

        //if last prod from category remove category
      }
    }

    /*function save({ prod, cat }: any) {
      const createdCats: any = []
      const defaultProdData = {
        mandatory: false,
        multiple: false,
        recommended: false,
        selected: false,
      }
      const defaultCatData = {
        priority: 0,
        mandatory: false,
        multiple: false,
      }

      let calls = 0

      function checkFinish() {
        if (calls === 0) {
          //do stuff
          emit("close-product-picker-dialog")
        }
      }

      categories.forEach((_cat: any) => {
        console.log(_cat)
        _cat.products.forEach((_prod: any) => {
          if (_prod.selected) {
            //prod needs to be added
            if (createdCats.indexOf(_cat.id) !== -1) {
              //already created
              //add product
              calls++
              salesService.createQuotationProduct(_cat.id, _prod.id, defaultProdData).then(() => {
                calls--
                checkFinish()
              })
            } else {
              //cat doesn't exist yet
              calls += 2
              console.log(route.params.id)
              salesService
                .createQuotationCategory(route.params.id as string, _cat.id, defaultCatData)
                .then((catId: string) => {
                  //add product
                  salesService.createQuotationProduct(catId, _prod.id, defaultProdData).then(() => {
                    calls -= 2
                    checkFinish()
                  })
                })
            }
          }
        })
      })
    }*/

    function toggleMultipleForCategory(cat: any): any {
      return true
    }

    function toggleMandatoryForCategory(cat: any): any {}

    watch(
      () => props.activeCategory,
      () => {
        handleActiveCategory()
      }
    )

    function handleActiveCategory() {
      categories.forEach((cat: any) => {
        cat.collapsed = cat.id !== props.activeCategory.productCategory.id
      })
    }

    return { categories, selectProduct, toggleMultipleForCategory, toggleMandatoryForCategory }
  },
})
