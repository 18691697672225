
import SalesService from "@/services/SalesService"
import { computed, reactive, ref, defineComponent, watch } from "vue"
import { useRouter, useRoute } from "vue-router"
import SalesQuotationComponent from "./salesQuotationComponent.vue"
import SalesQuotationProductsComponent from "./salesQuotationProductsComponent.vue"
import SalesProductPickerComponent from "./salesProductPickerComponent.vue"
import router from "@/router/router"
import { useConfirm } from "primevue/useconfirm"
import { longToShortCurrency } from "@/helpers/CurrencyHelpers"
import { useToast } from "primevue/usetoast"
import PrimaryButton from "../buttons/PrimaryButton.vue"
import SecondaryButton from "../buttons/SecondaryButton.vue"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"

export default defineComponent({
  components: {
    SalesQuotationComponent,
    SalesQuotationProductsComponent,
    SalesProductPickerComponent,
    PrimaryButton,
    SecondaryButton,
  },
  emits: ["close-and-reload"],
  setup(props: any, { emit }: any) {
    const route = useRoute()
    const salesService = new SalesService()
    const headerActive = ref(true)
    const bodyActive = ref(true)
    const productPickerVisible = ref(false)
    const quotation: any = reactive({})
    const linksDialogVisible = ref(false)
    const loading = ref(false)
    const confirm = useConfirm()
    const toast = useToast()
    const quotationRefreshKey = ref(0)
    const categoryNameFilterValue = ref()
    const categoryNameOptions = ["EQ DR", "EQ US", "SA DR", "SA US", "SA DT"]
    const noQuotationId = computed(
      () => route.params?.quotation_id === "0" || !route.params?.quotation_id
    )

    const quotationProductsApi: any = {
      loadImages: null,
    }

    const origin = computed(() => {
      return window.location.origin
    })

    const selectedCategories = computed(() => {
      if (quotation && quotation.categories) {
        return quotation?.categories.map((cat: any) => cat.id)
      }
    })

    const selectedProducts = computed(() => {
      if (quotation && quotation.categories) {
        return quotation?.categories
          .map((prod: any) => {
            return prod?.products
          })
          .flat()
          .filter((prod: any) => prod.selected)
      }
    })

    const activeCurrency = computed(() => {
      return quotation?.sale?.currency
    })

    const activeRegion = computed(() => {
      return quotation?.sale?.region
    })

    const totalPrice = computed(() => {
      return selectedProducts?.value?.reduce((acc: number, cur: any) => {
        if (
          (cur?.price?.adjustedPrice || cur?.price?.price) &&
          (cur.price.frequency === null || cur.price.frequency === "SINGLE")
        ) {
          let _price = cur.price.adjustedPrice ? cur.price.adjustedPrice : cur.price.price
          if (cur.amount > 0 && cur.multiple) {
            _price = _price * cur.amount
          }
          return acc + _price
        }
        return acc
      }, 0)
    })

    const totalPurchasePrice = computed(() => {
      return selectedProducts?.value?.reduce((acc: number, cur: any) => {
        if (cur?.price?.purchasePrice) {
          let _price = cur.price.purchasePrice
          if (cur.amount > 0 && cur.multiple) {
            _price = _price * cur.amount
          }
          return acc + _price
        }
        return acc
      }, 0)
    })

    function clearUnusedCategories() {
      quotation.categories.forEach((cat: any) => {
        if (cat?.products?.length === 0) {
          salesService.deleteQuotationCategory(cat.id)
        }
        loadQuotation()
      })
    }

    function loadQuotation(callback?: any) {
      let collapsedState: any
      if (quotation.categories) {
        collapsedState = quotation.categories.map((cat: any) => cat.collapsed)
      }

      if (!route.params.quotation_id || route.params?.quotation_id === "0") return
      salesService.getQuotations(route.params.quotation_id as string).then((_quotation: any) => {
        //delete unused quotations (normally there should be none...)
        /**/
        for (const key in quotation) {
          delete quotation[key]
        }

        Object.assign(quotation, _quotation)

        //open the same ones again after reloading
        if (collapsedState && collapsedState.length) {
          quotation.categories.forEach((cat: any, index: number) => {
            cat.collapsed = collapsedState[index]
          })
        }

        loading.value = false

        quotationProductsApi.loadImages && quotationProductsApi.loadImages()

        quotationRefreshKey.value++

        callback && callback()
      })
    }

    watch(
      () => route.params?.quotation_id,
      (newValue, oldValue) => {
        loadQuotation()
      }
    )

    if (route.params?.quotation_id) {
      loadQuotation()
    } else {
    }

    function closeProductPickerDialog() {
      productPickerVisible.value = false
      loadQuotation()
    }

    function createQuotationUrl() {
      salesService.createQuotationUrl(route.params.quotation_id as string).then(() => {
        emit("close-and-reload")
      })
    }

    function openPublicQuotation(link: string) {
      /*router.push({
        path: "/quotationlink/" + link,
      })*/

      window.open(location.origin + "/quotationlink/" + link, "_blank")
    }

    function changeLoadingState(state: boolean) {
      loading.value = true
    }

    const templateAlias = ref("")
    const templateForUser = ref(false)

    const showTemplateDialog = ref(false)
    function createNewTemplate() {
      salesService
        .addQuotationTemplate(quotation.id, templateAlias.value, templateForUser.value)
        .then((result) => {
          showTemplateDialog.value = false
          toast.add({
            severity: "success",
            summary: "Success",
            detail: "Added new template",
            life: 3000,
          })
        })
    }
    const totalAnnually = computed(() => {
      if (quotation && quotation.categories && quotation.categories.length) {
        return quotation.categories.reduce((acc: any, cur: any) => {
          const totalPrice = cur.products.reduce((_acc: any, _cur: any) => {
            if (_cur.selected && _cur?.price && _cur.price.frequency === "ANNUALLY") {
              let price = _cur.price.adjustedPrice ? _cur.price.adjustedPrice : _cur.price.price
              if (_cur.amount > 0 && _cur.multiple) {
                price = price * _cur.amount
              }
              acc += price
            }
            return acc
          }, 0)
          return totalPrice
        }, 0)
      } else {
        return 0
      }
    })

    const totalMonthly = computed(() => {
      if (quotation && quotation.categories && quotation.categories.length) {
        return quotation.categories.reduce((acc: any, cur: any) => {
          const totalPrice = cur.products.reduce((_acc: any, _cur: any) => {
            if (_cur.selected && _cur?.price && _cur.price.frequency === "MONTHLY") {
              let price = _cur.price.adjustedPrice ? _cur.price.adjustedPrice : _cur.price.price
              if (_cur.amount > 0 && _cur.multiple) {
                price = price * _cur.amount
              }
              acc += price
            }
            return acc
          }, 0)
          return totalPrice
        }, 0)
      } else {
        return 0
      }
    })

    const selectedCategory = ref()
    function handleSelectedCategory(category: any) {
      selectedCategory.value = category
    }

    function copyQuotationLink(link: string) {
      navigator.clipboard.writeText(link)
    }

    function resetViewsOfQuotationUrl() {
      salesService.resetViewsOfQuotationUrl(quotation?.links[0]?.id).then(() => {
        emit("close-and-reload")
      })
    }

    watch(
      () => bodyActive.value,
      (newValue) => {
        if (!newValue) {
          categoryNameFilterValue.value = null
        }
      }
    )

    return {
      headerActive,
      bodyActive,
      productPickerVisible,
      quotation,
      selectedProducts,
      selectedCategories,
      loadQuotation,
      totalPrice,
      totalPurchasePrice,
      createQuotationUrl,
      linksDialogVisible,
      openPublicQuotation,
      loading,
      changeLoadingState,
      origin,
      createNewTemplate,
      showTemplateDialog,
      templateForUser,
      templateAlias,
      activeCurrency,
      activeRegion,
      quotationProductsApi,
      totalMonthly,
      totalAnnually,
      longToShortCurrency,
      noQuotationId,
      handleSelectedCategory,
      selectedCategory,
      quotationRefreshKey,
      copyQuotationLink,
      categoryNameFilterValue,
      categoryNameOptions,
      resetViewsOfQuotationUrl,
    }
  },
})
